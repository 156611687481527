import React from "react"
import PropTypes from "prop-types"
import Client from "shopify-buy"

import Header from "../components/header"
import Footer from "../components/footer"

import "../assets/scss/main.scss"

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isCartOpen: false,
      isSearching: false,
      cartRequest: false,
      checkout: { lineItems: [] },
      products: [],
      shop: {},
    }
  }

  componentDidMount() {
    this.client = Client.buildClient({
      domain: "shop.jessicacoppetstudio.com",
      storefrontAccessToken: "c74b3166abec61985f36e7525bc5ef3f",
    })
    this.client.checkout.create().then((checkout) => {
      this.setState({ checkout })
    })
  }

  _fetchProducts(query) {
    this.setState({
      ...this.state,
      isSearching: true,
    })
    this.client.product.fetchAll().then((products) => {
      this.setState({
        ...this.state,
        isSearching: false,
        products: products.filter(
          (product) =>
            product.title.toLowerCase().includes(query.toLowerCase()) ||
            product.productType.toLowerCase().includes(query.toLowerCase())
        ),
      })
    })
  }

  _addVariantToCart(variantId, quantity) {
    this.setState({ isCartOpen: true, cartRequest: true })

    if (variantId.indexOf("Shopify__ProductVariant__") !== -1)
      variantId = variantId.split("Shopify__ProductVariant__")[1]

    const lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }]
    const checkoutId = this.state.checkout.id

    return this.client.checkout
      .addLineItems(checkoutId, lineItemsToAdd)
      .then((checkout) => this.setState({ checkout, cartRequest: false }))
  }

  _updateQuantityInCart(lineItemId, quantity) {
    const checkoutId = this.state.checkout.id
    const lineItemsToUpdate = [
      { id: lineItemId, quantity: parseInt(quantity, 10) },
    ]
    this.setState({ isCartOpen: true, cartRequest: true })

    return this.client.checkout
      .updateLineItems(checkoutId, lineItemsToUpdate)
      .then((checkout) => this.setState({ checkout, cartRequest: false }))
  }

  _removeLineItemInCart(lineItemId) {
    const checkoutId = this.state.checkout.id
    this.setState({ isCartOpen: true, cartRequest: true })

    return this.client.checkout
      .removeLineItems(checkoutId, [lineItemId])
      .then((checkout) => this.setState({ checkout, cartRequest: false }))
  }

  render() {
    let { children, location } = this.props
    const {
      checkout,
      isCartOpen,
      isSearching,
      cartRequest,
      products,
    } = this.state

    children = React.Children.map(children, (child) =>
      React.cloneElement(child, {
        addVariantToCart: this._addVariantToCart.bind(this),
        updateQuantityInCart: this._updateQuantityInCart.bind(this),
        removeLineItemInCart: this._removeLineItemInCart.bind(this),
        fetchProducts: this._fetchProducts.bind(this),
        checkout: checkout,
        products: products,
        isSearching: isSearching,
      })
    )

    return (
      <>
        <Header
          hasTopBar={location.pathname === "/about"}
          pathname={location.pathname}
          isCartOpen={isCartOpen}
          setIsCartOpen={(val) => this.setState({ isCartOpen: val })}
          cartRequest={cartRequest}
          cartCount={checkout.lineItems.length}
          checkout={checkout}
          updateQuantityInCart={this._updateQuantityInCart.bind(this)}
          removeLineItemInCart={this._removeLineItemInCart.bind(this)}
        />
        <main>{children}</main>
        <Footer />
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
}

export default Layout
