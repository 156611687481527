import React from "react"
import PropTypes from "prop-types"

import Link from "../utils/link"

// import TopBar from "./TopBar"
import Announcement from "./announcement"
import Spacer from "./spacer"
import Cart from "./cart"

import JCDarkLogo from "../assets/images/icons/JC-dark.png"
import JCLightLogo from "../assets/images/icons/JC-light.png"
import FullLogo from "../assets/images/icons/JessicaWordmark.svg"
import CartIcon from "../assets/images/icons/cart.svg"
import SearchIcon from "../assets/images/icons/search.svg"
import MenuIcon from "../assets/images/icons/menu.svg"
import CloseIcon from "../assets/images/icons/close.svg"

import EtsyLogo from "../assets/images/icons/etsy.svg"
import FacebookLogo from "../assets/images/icons/facebook.svg"
import InstagramLogo from "../assets/images/icons/instagram.svg"
import PinterestLogo from "../assets/images/icons/pinterest.svg"
import EnvelopeLogo from "../assets/images/icons/envelope.svg"

import "./header.scss"

const Header = ({
  hasTopBar,
  pathname,
  isCartOpen,
  setIsCartOpen,
  cartRequest,
  cartCount,
  checkout,
  updateQuantityInCart,
  removeLineItemInCart,
}) => {
  const [isVisible, setIsVisible] = React.useState(false)
  const [isSearchExpanded, setIsSearchExpanded] = React.useState(false)
  // const [isCartExpanded, setIsCartExpanded] = React.useState(false)
  const [query, setQuery] = React.useState("")

  React.useEffect(() => {
    setIsVisible(false)
  }, [pathname])

  const toggleIsVisible = () => {
    setIsVisible(!isVisible)
  }
  const toggleIsSearchExpanded = () => {
    setIsSearchExpanded(!isSearchExpanded)
  }
  const toggleIsCartExpanded = () => {
    setIsCartOpen(!isCartOpen)
  }
  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      window.location.href = `/search?q=${query}`
    }
  }

  return (
    <>
      <header className="header">
        <Announcement />
        <div className="header__container">
          <button onClick={toggleIsVisible} className="header__hamburger">
            {isVisible ? <img src={CloseIcon} /> : <img src={MenuIcon} />}
          </button>
          <div className={`header__start ${isVisible ? "active" : ""}`}>
            <div className="header__start__link">
              <div className="header__start__link__dropdown">
                <button className="header__start__link__dropdown__button">
                  Print
                </button>
                <div className="header__start__link__dropdown__content">
                  <Link to="/shop/all-prints/" onClick={toggleIsVisible}>
                    All Prints
                  </Link>
                  <Link
                    to="/shop/portraits/"
                    className="is-nested"
                    onClick={toggleIsVisible}
                  >
                    Portraits
                  </Link>
                  <Link
                    to="/shop/women/"
                    onClick={toggleIsVisible}
                    className="is-nested"
                  >
                    Women
                  </Link>
                  <Link to="/shop/couples/" onClick={toggleIsVisible}>
                    Couples
                  </Link>
                  <Link to="/shop/animals-nature/" onClick={toggleIsVisible}>
                    Animals &amp; Nature
                  </Link>
                  <Link to="/shop/quotes/" onClick={toggleIsVisible}>
                    Inspirational Quotes
                  </Link>
                  <Link to="/shop/print-sets/" onClick={toggleIsVisible}>
                    Print Sets
                  </Link>
                </div>
              </div>
            </div>
            <div className="header__start__link">
              <div className="header__start__link__dropdown">
                <button className="header__start__link__dropdown__button">
                  Apparel
                </button>
                <div className="header__start__link__dropdown__content">
                  <Link to="/shop/apparel/" onClick={toggleIsVisible}>
                    All
                  </Link>
                  <Link
                    to="/shop/womens/"
                    className="is-nested"
                    onClick={toggleIsVisible}
                  >
                    Womens
                  </Link>
                  <Link
                    to="/shop/mens/"
                    onClick={toggleIsVisible}
                    className="is-nested"
                  >
                    Mens
                  </Link>
                </div>
              </div>
            </div>
            <div className="header__start__link">
              <div className="header__start__link__dropdown">
                <button className="header__start__link__dropdown__button">
                  Other
                </button>
                <div className="header__start__link__dropdown__content">
                  <Link to="/shop/stationery/" onClick={toggleIsVisible}>
                    Stationery
                  </Link>
                  <Link
                    to="/shop/home-accessories/"
                    className="is-nested"
                    onClick={toggleIsVisible}
                  >
                    Home Accessories
                  </Link>
                  <Link
                    to="/shop/gift-card/"
                    onClick={toggleIsVisible}
                    className="is-nested"
                  >
                    Gift Cards
                  </Link>
                </div>
              </div>
            </div>
            <div className="header__start__link">
              <Link to="/commissions">Commissions</Link>
            </div>
            <div className="header__start__link">
              <Link to="/about">About</Link>
            </div>
            <div className="header__start__mobile">
              <div className="header__start__link head">
                <Link to="#">Customer Service</Link>
              </div>
              <div className="header__start__link sublink">
                <Link to="#">Track your order</Link>
              </div>
              <div className="header__start__link sublink">
                <Link to="/refund-policy">Shipping & Returns</Link>
              </div>
              <div className="header__start__link sublink">
                <Link to="/privacy-policy">Privacy Policy</Link>
              </div>
              <div className="header__start__link sublink">
                <Link to="/terms-of-service">Terms & Conditions</Link>
              </div>
            </div>
            <div className="header__start__social">
              <Link to="https://www.etsy.com/in-en/shop/JessicaCoppetDesign">
                <img src={EtsyLogo} alt="Etsy" />
              </Link>
              <Link to="https://www.facebook.com/jessicacoppetillustration/">
                <img src={FacebookLogo} alt="Facebook" />
              </Link>
              <Link to="https://www.instagram.com/jessicacoppetillustration/">
                <img src={InstagramLogo} alt="Instagram" />
              </Link>
              <Link to="https://www.pinterest.com.au/jessicacoppet/">
                <img src={PinterestLogo} alt="Pinterest" />
              </Link>
              <Link to="mailto:hello@jessicacoppet.com">
                <img src={EnvelopeLogo} alt="Envelope" />
              </Link>
            </div>
          </div>
          <div className="header__end">
            {isSearchExpanded && (
              <div className="header__end__link">
                <input
                  type="text"
                  className="header__end__link__search"
                  value={query}
                  onKeyUp={handleKeyPress}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
            )}
            <div className={`header__end__link ${isVisible ? "open" : ""}`}>
              <img
                src={SearchIcon}
                alt="Search Icon"
                onClick={toggleIsSearchExpanded}
              />
            </div>
            <div className={`header__end__link ${isVisible ? "open" : ""}`}>
              {cartCount > 0 && (
                <span className="header__end__link__count">{cartCount}</span>
              )}
              <img
                src={CartIcon}
                alt="Cart Icon"
                onClick={toggleIsCartExpanded}
              />
              {isCartOpen && (
                <Cart
                  toggleIsCartExpanded={toggleIsCartExpanded}
                  isCartOpen={isCartOpen}
                  cartRequest={cartRequest}
                  cartCount={cartCount}
                  checkout={checkout}
                  updateQuantityInCart={updateQuantityInCart}
                  removeLineItemInCart={removeLineItemInCart}
                />
              )}
            </div>
            <div className="header__end__logo">
              <Link to="/">
                <img
                  src={FullLogo}
                  alt="Jessica Coppet"
                  className="large-only"
                />
                {isVisible ? (
                  <img
                    src={JCLightLogo}
                    alt="Jessica Coppet"
                    className="small-only"
                  />
                ) : (
                  <img
                    src={JCDarkLogo}
                    alt="Jessica Coppet"
                    className="small-only"
                  />
                )}
              </Link>
            </div>
          </div>
        </div>
      </header>
      <Spacer />
    </>
  )
}

Header.propTypes = {
  hasTopBar: PropTypes.bool.isRequired,
  pathname: PropTypes.string.isRequired,
  isCartOpen: PropTypes.bool.isRequired,
  cartRequest: PropTypes.bool.isRequired,
  cartCount: PropTypes.number.isRequired,
  checkout: PropTypes.object.isRequired,
  updateQuantityInCart: PropTypes.func.isRequired,
  removeLineItemInCart: PropTypes.func.isRequired,
}

export default Header
