import React from "react"

import Link from "../utils/link"

import NewsletterModal from "./NewsletterModal"

import JCLogo from "../assets/images/icons/JC-light.svg"
import EtsyLogo from "../assets/images/icons/etsy.svg"
import FacebookLogo from "../assets/images/icons/facebook.svg"
import InstagramLogo from "../assets/images/icons/instagram.svg"
import PinterestLogo from "../assets/images/icons/pinterest.svg"
import EnvelopeLogo from "../assets/images/icons/envelope.svg"
import "./footer.scss"

const Footer = () => {
  const [isVisible, setIsVisible] = React.useState(false)
  const [isShopExpanded, setIsShopExpanded] = React.useState(false)
  const [
    isCustomerServiceExpanded,
    setIsCustomerServiceExpanded,
  ] = React.useState(false)

  const toggleIsVisible = () => {
    setIsVisible(!isVisible)
  }

  const toggleIsShopExpanded = () => {
    setIsShopExpanded(!isShopExpanded)
  }
  const toggleIsCustomerServiceExpanded = () => {
    setIsCustomerServiceExpanded(!isCustomerServiceExpanded)
  }

  return (
    <>
      <footer className="footer">
        <div className="footer__container">
          <div className="footer__top">
            <div className="footer__top__left">
              <Link to="/" title="Jessica Coppet" className="footer__top__logo">
                <img src={JCLogo} alt="Jessica Coppet" />
              </Link>
              <div className="footer__top__links">
                <div className="footer__top__links__first">
                  <div
                    className="footer__top__links__link cursor-pointer"
                    onClick={toggleIsShopExpanded}
                  >
                    Shop
                  </div>
                  <div
                    className={`mobile-only ${
                      isShopExpanded ? "is-visible" : ""
                    }`}
                  >
                    <div className="footer__top__links__link sublink">
                      <Link to="/shop/all-prints">All Prints</Link>
                    </div>
                    <div className="footer__top__links__link sublink">
                      <Link to="/shop/black-and-white-prints/">
                        Black & White Prints
                      </Link>
                    </div>
                    <div className="footer__top__links__link sublink">
                      <Link to="/shop/colour-prints/">Colour Prints</Link>
                    </div>
                    <div className="footer__top__links__link sublink">
                      <Link to="/shop/apparel/">Apparel</Link>
                    </div>
                  </div>
                  <div className="footer__top__links__link">
                    <Link to="/commissions/">Commissions</Link>
                  </div>
                  <div className="footer__top__links__link">
                    <Link to="/about/">About</Link>
                  </div>
                </div>
                <div className="footer__top__links__last">
                  <div
                    className="footer__top__links__link headerlink"
                    onClick={toggleIsCustomerServiceExpanded}
                  >
                    Customer Service
                  </div>
                  <div
                    className={`links ${
                      isCustomerServiceExpanded ? "is-visible" : ""
                    }`}
                  >
                    <div className="footer__top__links__link sublink">
                      <Link to="#">Track your order</Link>
                    </div>
                    <div className="footer__top__links__link sublink">
                      <Link to="/shipping-returns">Shipping & Returns</Link>
                    </div>
                    <div className="footer__top__links__link sublink">
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </div>
                    <div className="footer__top__links__link sublink">
                      <Link to="/terms-of-service">Terms & Conditions</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer__top__newsletter">
              <p>
                Join Jessica’s mailing list for all exclusive offers, updates
                and news
              </p>
              <div className="field">
                <input type="email" placeholder="Your Email" required />
                <span className="icon" onClick={toggleIsVisible}>
                  <svg
                    width="16px"
                    height="10px"
                    viewBox="0 0 16 10"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>np_arrow_1569918_000000</title>
                    <defs>
                      <filter id="filter-1">
                        <feColorMatrix
                          in="SourceGraphic"
                          type="matrix"
                          values="0 0 0 0 0.956863 0 0 0 0 0.945098 0 0 0 0 0.917647 0 0 0 1.000000 0"
                        ></feColorMatrix>
                      </filter>
                    </defs>
                    <g
                      id="Symbols"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <g
                        id="np_arrow_1569918_000000"
                        transform="translate(-1359.000000, -167.000000)"
                        filter="url(#filter-1)"
                      >
                        <g transform="translate(1359.000000, 167.000000)">
                          <path
                            d="M15.7894737,5 C15.7894737,4.8787 15.7154605,4.71628947 15.6414474,4.63815789 L11.4309211,0.164473684 C11.2345816,-0.0390631579 10.9159211,-0.0627052632 10.6866842,0.143914474 C10.4821211,0.327919737 10.4769789,0.687703947 10.666125,0.888151316 L14.046125,4.47367763 L0.526388158,4.47367763 C0.235467105,4.47367763 7.23684211e-05,4.70908026 7.23684211e-05,4.99999342 C7.23684211e-05,5.29090658 0.235475,5.52630921 0.526388158,5.52630921 L14.046125,5.52630921 L10.666125,9.11183553 C10.4769803,9.31228816 10.4903434,9.66283553 10.6866842,9.85607237 C10.8943316,10.0596092 11.2376842,10.0421329 11.4309211,9.83551316 L15.6414474,5.36182895 C15.7648026,5.24052895 15.7874184,5.12334211 15.7894737,4.99998684 L15.7894737,5 Z"
                            id="Path"
                            fill="#000000"
                            fillRule="nonzero"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div className="footer__bottom">
            <div className="footer__bottom__copy">
              © Jessica Coppet 2020. All rights reserved&nbsp;|&nbsp;
              <Link to="https://atollon.com.au">Website by Atollon</Link>
            </div>
            <div className="footer__bottom__social">
              <Link to="https://www.etsy.com/in-en/shop/JessicaCoppetDesign">
                <img src={EtsyLogo} alt="Etsy" />
              </Link>
              <Link to="https://www.facebook.com/jessicacoppetillustration/">
                <img src={FacebookLogo} alt="Facebook" />
              </Link>
              <Link to="https://www.instagram.com/jessicacoppetillustration/">
                <img src={InstagramLogo} alt="Instagram" />
              </Link>
              <Link to="https://www.pinterest.com.au/jessicacoppet/">
                <img src={PinterestLogo} alt="Pinterest" />
              </Link>
              <Link to="mailto:hello@jessicacoppet.com">
                <img src={EnvelopeLogo} alt="Envelope" />
              </Link>
            </div>
          </div>
        </div>
      </footer>
      {isVisible && (
        <NewsletterModal
          isVisible={isVisible}
          toggleIsVisible={toggleIsVisible}
        />
      )}
    </>
  )
}

export default Footer
