import React from "react"
import PropTypes from "prop-types"

import JessicaCoppet from "../assets/images/icons/JessicaCoppet.svg"
import ExitIcon from "../assets/images/icons/exit.svg"
import "./NewsletterModal.scss"

const NewsletterModal = ({ isVisible, toggleIsVisible }) => {
  return (
    <section className="newsletter-modal">
      <div className="newsletter-modal__background" onClick={toggleIsVisible} />
      <div className="newsletter-modal__box">
        <div className="newsletter-modal__box__close">
          <img src={ExitIcon} onClick={toggleIsVisible} />
        </div>
        <div className="newsletter-modal__box__inner">
          <div className="newsletter-modal__box__inner__info">
            <div className="newsletter-modal__box__inner__info__logo">
              <img src={JessicaCoppet} alt="Jessica Coppet" />
            </div>
            <div className="newsletter-modal__box__inner__info__text">
              Sign up to Jessica’s mailing list for all exclusive offers,
              updates and news.
            </div>
          </div>
          <div className="newsletter-modal__box__inner__fields">
            <form>
              <div className="newsletter-modal__box__inner__fields__field">
                <input
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  required
                />
              </div>
              <div className="newsletter-modal__box__inner__fields__field">
                <input
                  type="email"
                  name="name"
                  placeholder="Your Email"
                  required
                />
              </div>
              <button type="submit">Subscribe</button>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

NewsletterModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  toggleIsVisible: PropTypes.func.isRequired,
}

export default NewsletterModal
