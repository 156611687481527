import React from "react"
import { graphql, useStaticQuery } from "gatsby"

function Spacer() {
  const data = useStaticQuery(graphql`
    query {
      shopifyPage(title: {eq: "Announcement"}) {
        body
      }
    }
  `)
  return (
    <>
      { data.shopifyPage.body &&
        <section className='spacer' style={{height: "36px"}} />
      }
    </>
  )
}

export default Spacer