import React from "react"
import PropTypes from "prop-types"

import Link from "../utils/link"
import useClickOutside from "../hooks/useClickOutside"

import TriangleIcon from "../assets/images/icons/triangle.svg"
import ExitIcon from "../assets/images/icons/exit.svg"
import "./cart.scss"

const Cart = ({ toggleIsCartExpanded, checkout, updateQuantityInCart }) => {
  const clickRef = React.useRef()

  useClickOutside(clickRef, () => {
    toggleIsCartExpanded()
  })

  const decreaseQuantity = (id, quantity) => {
    updateQuantityInCart(id, quantity - 1)
  }

  const increaseQuantity = (id, quantity) => {
    updateQuantityInCart(id, quantity + 1)
  }

  return (
    <div className="cart" ref={clickRef}>
      <div className="cart__triangle">
        <img src={TriangleIcon} />
      </div>
      <div className="cart__head">
        <div className="cart__head__title">Shopping cart</div>
        <div className="cart__head__close">
          <img src={ExitIcon} onClick={toggleIsCartExpanded} />
        </div>
      </div>
      {checkout.lineItems.length === 0 ? (
        <div className="cart__empty">Cart Empty</div>
      ) : (
        <div className="cart__items">
          {checkout.lineItems.map((item, index) => (
            <div className="cart__item" key={index}>
              <div
                className="cart__item__image"
                style={{ backgroundImage: `url(${item.variant.image.src})` }}
              />
              <div className="cart__item__details">
                <div className="cart__item__details__title">{item.title}</div>
                <div className="cart__item__details__variant">
                  {item.variant.title}
                </div>
                <div className="cart__item__details__quantity">
                  <div
                    className="cart__item__details__quantity__control"
                    onClick={() => decreaseQuantity(item.id, item.quantity)}
                  >
                    -
                  </div>
                  <div className="cart__item__details__quantity__value">
                    {item.quantity}
                  </div>
                  <div
                    className="cart__item__details__quantity__control"
                    onClick={() => increaseQuantity(item.id, item.quantity)}
                  >
                    +
                  </div>
                  <div className="cart__item__details__quantity__text">QTY</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="cart__total">Total</div>
      <div className="cart__amount">$ {checkout.totalPrice} AUD</div>
      {checkout.lineItems.length > 0 && (
        <Link to={checkout.webUrl} className="cart__checkout">
          Checkout
        </Link>
      )}
    </div>
  )
}

Cart.propTypes = {
  toggleIsCartExpanded: PropTypes.func.isRequired,
  checkout: PropTypes.object.isRequired,
  updateQuantityInCart: PropTypes.func.isRequired,
}

export default Cart
