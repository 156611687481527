// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-commissions-js": () => import("./../../../src/pages/commissions.js" /* webpackChunkName: "component---src-pages-commissions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-refund-policy-js": () => import("./../../../src/pages/refund-policy.js" /* webpackChunkName: "component---src-pages-refund-policy-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-shipping-returns-js": () => import("./../../../src/pages/shipping-returns.js" /* webpackChunkName: "component---src-pages-shipping-returns-js" */),
  "component---src-pages-shop-all-prints-js": () => import("./../../../src/pages/shop/all-prints.js" /* webpackChunkName: "component---src-pages-shop-all-prints-js" */),
  "component---src-pages-shop-animals-nature-js": () => import("./../../../src/pages/shop/animals-nature.js" /* webpackChunkName: "component---src-pages-shop-animals-nature-js" */),
  "component---src-pages-shop-apparel-js": () => import("./../../../src/pages/shop/apparel.js" /* webpackChunkName: "component---src-pages-shop-apparel-js" */),
  "component---src-pages-shop-black-and-white-prints-js": () => import("./../../../src/pages/shop/black-and-white-prints.js" /* webpackChunkName: "component---src-pages-shop-black-and-white-prints-js" */),
  "component---src-pages-shop-colour-prints-js": () => import("./../../../src/pages/shop/colour-prints.js" /* webpackChunkName: "component---src-pages-shop-colour-prints-js" */),
  "component---src-pages-shop-couples-js": () => import("./../../../src/pages/shop/couples.js" /* webpackChunkName: "component---src-pages-shop-couples-js" */),
  "component---src-pages-shop-home-accessories-js": () => import("./../../../src/pages/shop/home-accessories.js" /* webpackChunkName: "component---src-pages-shop-home-accessories-js" */),
  "component---src-pages-shop-mens-js": () => import("./../../../src/pages/shop/mens.js" /* webpackChunkName: "component---src-pages-shop-mens-js" */),
  "component---src-pages-shop-portraits-js": () => import("./../../../src/pages/shop/portraits.js" /* webpackChunkName: "component---src-pages-shop-portraits-js" */),
  "component---src-pages-shop-print-sets-js": () => import("./../../../src/pages/shop/print-sets.js" /* webpackChunkName: "component---src-pages-shop-print-sets-js" */),
  "component---src-pages-shop-quotes-js": () => import("./../../../src/pages/shop/quotes.js" /* webpackChunkName: "component---src-pages-shop-quotes-js" */),
  "component---src-pages-shop-stationery-js": () => import("./../../../src/pages/shop/stationery.js" /* webpackChunkName: "component---src-pages-shop-stationery-js" */),
  "component---src-pages-shop-women-js": () => import("./../../../src/pages/shop/women.js" /* webpackChunkName: "component---src-pages-shop-women-js" */),
  "component---src-pages-shop-womens-js": () => import("./../../../src/pages/shop/womens.js" /* webpackChunkName: "component---src-pages-shop-womens-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

