import React from "react"
import { graphql, useStaticQuery } from "gatsby"

function Announcement() {
  const data = useStaticQuery(graphql`
    query {
      shopifyPage(title: {eq: "Announcement"}) {
        body
      }
    }
  `)
  return (
    <>
      { data.shopifyPage.body &&
        <section className='announcement'>
          <div className="announcement__inner">
            <p>{data.shopifyPage.body}</p>
          </div>
        </section>
      }
    </>
  )
}

export default Announcement